<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" :disabled="!store.accessRightsHash.VEHICLE_INSPECTION_EDIT" @click="operation.add.click">添加车品牌类别</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <table-dynamic
          :height="600"
          code="VHIETPFD"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
        >
          <template #OPERATION="{ row }: { row: EnocloudCommonDefinitions['VehicleTypeDto'] }">
            <button-delete :method="table.operation.delete.click" :params="row"> 删除 </button-delete>
          </template>
          <template #NAME="{ row }: { row: EnocloudCommonDefinitions['VehicleTypeDto'] }">
            <en-button type="primary" text @click="table.name.click(row)">{{ row.name }}</en-button>
          </template>
          <template #ADJUST_CATEGORY="{ row }: { row: EnocloudCommonDefinitions['VehicleTypeDto'] }">
            {{ row.adjustingValue && row.adjustingValue > 0 ? '上浮' : '下浮' }}
          </template>
          <template #ADJUST_RATIO="{ row }: { row: EnocloudCommonDefinitions['VehicleTypeDto'] }">
            {{ row.adjustingMethod && row.adjustingMethod.code === 'P' ? `${(Math.abs(Number(row.adjustingValue)) * 100).toFixed(2)}%` : '--' }}
          </template>
          <template #ADJUST_AMOUNT="{ row }: { row: EnocloudCommonDefinitions['VehicleTypeDto'] }">
            {{ row.adjustingMethod.code === 'P' ? '--' : row.adjustingValue.toFixed(2) }}
          </template>
        </table-dynamic>
      </template>
    </flex-box>
  </view-item-setting>

  <en-drawer v-model="detail.visible" :title="detail.form.data.id ? '编辑' : '添加' + '车品牌类型'">
    <en-form :model="detail.form.data" :rules="detail.form.rules" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="车品牌类别名称" prop="name">
        <en-input v-model="detail.form.data.name"></en-input>
      </en-form-item>
      <en-form-item label="对照基础浮动">
        <select-maintain
          v-model="detail.form.data.adjustingMethod"
          :ajax="{
            action: 'GET /enocloud/common/lookup/:lookupType',
            params: (params) => (params.paths = ['BENTYPE'])
          }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="对照基础浮动比例" prop="adjustingValuePercent">
        <en-input :max="100" :min="-100" :step="5" controls-position="right" v-model="detail.form.data.adjustingValuePercent" class="w-full"
          ><template #append>%</template></en-input
        >
      </en-form-item>
      <en-form-item label="备注" prop="comment">
        <en-input v-model="detail.form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },

      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/common/vehicle/type',
            data: 'array',
            loading: true,
            pagination: true
          },
          delete: {
            action: 'DELETE /enocloud/common/vehicle/type/:vehicleTypeId',
            loading: true
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnocloudCommonDefinitions['VehicleInspectionCategoryDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            }
          },
          name: {
            click(row: EnocloudCommonDefinitions['VehicleTypeDto']) {
              this.detail.form.init()
              this.detail.form.data.id = row.id
              this.detail.form.get()
              this.detail.visible = true
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              adjustingMethod: {
                code: 'P'
              },
              adjustingValue: 0,
              adjustingValuePercent: 0,
              comment: ''
            },
            ajax: {
              get: {
                action: 'GET /enocloud/common/vehicle/type/:vehicleTypeId',
                data: 'object',
                loading: true,
                params(params) {
                  params.paths = [this.detail.form.data.id]
                }
              },
              submit: {
                action: 'POST /enocloud/common/vehicle/type',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enocloud/common/vehicle/type',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            },
            rules: {
              name: [{ required: true, message: '请输入车品牌类别名称' }]
            }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
